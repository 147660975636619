import * as Sentry from '@sentry/react'
import { COMMON_ERROR_MESSAGE } from 'api'
import config from 'config'

export const findBrainNotebooks = () => {
  return fetch(`${config.brain.url}/api/external/visible-notebooks`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${config.brain.token}`,
      'Content-Type': 'application/json'
    }
  })
    .then(async response => {
      const JSONResponse = await response.json()
      if (response.ok) return JSONResponse.body
      throw new Error(JSONResponse.message || COMMON_ERROR_MESSAGE)
    })
    .catch(e => {
      Sentry.captureException(e)
      throw e
    })
}
