//@ts-check
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { assoc, groupBy, times } from 'ramda'
import DotsSpinner from 'components/spinners/DotsSpinner'
import Table from 'components/tables/Table'
import DatePicker from 'components/inputs/DatePicker'
import { Paragraph } from 'components/typography'
import Tooltip from 'components/tooltips/Tooltip'
import { getPlanningTeacher } from 'api/planning'
import { dateToString, toTimeString } from 'utils/date'
import { AttendanceTypeLabels } from 'utils/constants'
import AttendanceHourSelector from './AttendanceHourSelector'
import { useNotificationActions } from 'context/NotificationProvider'
import styles from './Planning.module.css'

function spreadAttendancesByHours(attendances) {
  const result = {}

  const padTime = num => num.toString().padStart(2, '0')

  attendances.forEach(attendance => {
    const classStart = new Date(attendance.classStart)
    const classEnd = new Date(attendance.classEnd)
    let currentTime = new Date(classStart)

    while (currentTime < classEnd) {
      const hours = padTime(currentTime.getHours())
      const minutes = padTime(currentTime.getMinutes())
      const targetHour = `${hours}:${minutes}`

      if (!result[targetHour]) {
        result[targetHour] = []
      }
      result[targetHour].push({ ...attendance, targetHour })
      currentTime.setHours(currentTime.getHours() + 1)
    }
  })

  return result
}

export function makeInitialHour(date = new Date()) {
  const aux = new Date(date)
  const hour = aux.getHours()

  if (hour < 15) date.setMinutes(0, 0, 0)
  if (hour >= 15) date.setMinutes(30, 0, 0)
  return toTimeString(date)
}

const columns = [
  {
    key: 'range',
    label: 'Duración'
  },
  {
    key: 'studentName',
    label: 'Alumno'
  },
  { key: 'subjectName', label: 'Asignatura' },
  { key: 'subjectLevel', label: 'Nivel' },
  { key: 'type', label: 'Tipo clase' }
]
function Planning() {
  const [state, setState] = useState({
    selectedDate: new Date(),
    selectedHour: makeInitialHour(),
    attendances: [],
    isLoading: false
  })
  const { setErrorMessage } = useNotificationActions()

  const handleDateChange = useCallback(e => {
    setState(assoc('selectedDate', e.target.value))
  }, [])

  const attendancesByHour = useMemo(() => {
    return spreadAttendancesByHours(state.attendances)
  }, [state.attendances])

  const renderTabContent = ({ attendances }) => {
    return (
      <div>
        {state.isLoading ? (
          <DotsSpinner />
        ) : (
          <div>
            {attendances.length > 0 ? (
              <Table
                data={attendances.map(attendanceDTO => ({
                  ...attendanceDTO,
                  range: `${toTimeString(
                    attendanceDTO.classStart
                  )} - ${toTimeString(attendanceDTO.classEnd)}`,
                  studentName: (
                    <Tooltip
                      label={`${toTimeString(
                        attendanceDTO.classStart
                      )} - ${toTimeString(attendanceDTO.classEnd)}`}
                    >
                      {attendanceDTO.studentName}
                    </Tooltip>
                  ),
                  type: AttendanceTypeLabels[attendanceDTO.type]
                }))}
                columns={columns}
              />
            ) : (
              <Paragraph className={styles.noClassesMessage}>
                No hay clases programadas para esta hora
              </Paragraph>
            )}
          </div>
        )}
      </div>
    )
  }

  useEffect(() => {
    // @ts-ignore
    if (isNaN(Date.parse(state.selectedDate))) return
    setState(prevState => ({ ...prevState, isLoading: true }))
    getPlanningTeacher({ date: state.selectedDate })
      .then(data => {
        setState(prevState => ({ ...prevState, attendances: data }))
      })
      .catch(error => {
        setErrorMessage({ message: error.message })
        console.error('Error fetching planning data: ', error)
      })
      .finally(() => {
        setState(prevState => ({ ...prevState, isLoading: false }))
      })
  }, [state.selectedDate, setErrorMessage])

  return (
    <section className={styles.section}>
      <AttendanceHourSelector
        selectedHour={state.selectedHour}
        attendancesByHour={attendancesByHour}
        renderTabContent={renderTabContent}
        onSelectHour={selectedHour =>
          setState(prevState => ({ ...prevState, selectedHour }))
        }
      />

      <div className={styles.scheduleInfoContainer}>
        <Paragraph>
          Clases del día <strong>{dateToString(state.selectedDate)}</strong> a
          las <strong>{state.selectedHour}</strong>
        </Paragraph>
        <div className={styles.datePickerContainer}>
          <DatePicker
            name='selectedDate'
            placeholder='Filtrar por fecha'
            value={state.selectedDate || null}
            onChange={handleDateChange}
          />
        </div>
      </div>
    </section>
  )
}

export default Planning
